import React, {
  useContext, useEffect, useMemo, useReducer,
} from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

// Libs
import secureLocalStorage from 'react-secure-storage';

// Utils
import { differenceInHours } from 'date-fns';
import { cloneDeep } from 'lodash';

import appReducer from './appReducer';

const AppStateContext = React.createContext();
const AppDispatchContext = React.createContext();

const initStore = {
  bookingForm: null,
  bookingResidence: null,
};

function AppProvider({ children }) {
  const router = useRouter();

  const [state, dispatch] = useReducer(
    (st, act) => appReducer({
      state: st, action: act, initStore,
    }),
    initStore,
  );

  const isClientSide = (typeof window !== 'undefined');

  const handleBookingFormPopulation = () => {
    dispatch({
      type: 'SET_BOOKING_FORM',
      payload: secureLocalStorage.getItem('booking') || {},
    });
    dispatch({
      type: 'SET_BOOKING_RESIDENCE',
      payload: secureLocalStorage.getItem('bookingResidence') || null,
    });
  };

  const handleClearBookingStorage = () => {
    dispatch({ type: 'RESET_BOOKING', payload: {} });
    secureLocalStorage.removeItem('booking');
    secureLocalStorage.removeItem('bookingResidence');
  };

  const handleBookingVersionCheck = () => {
    const appVersion = 1.2;
    const storedAppVersion = secureLocalStorage.getItem('appVersion');

    if (!storedAppVersion) {
      secureLocalStorage.setItem('appVersion', appVersion);
    } else if (storedAppVersion !== appVersion) {
      router.push('/').then(() => {
        handleClearBookingStorage();
        secureLocalStorage.setItem('appVersion', appVersion);
      });
    }
  };

  const handleBookingCreationDate = () => {
    // clear booking 24h after creation
    const bookingFormCreatedAt = secureLocalStorage.getItem('booking')?.createdAt;

    if (bookingFormCreatedAt) {
      const createdAtDate = new Date(bookingFormCreatedAt);
      const now = new Date();
      const time = differenceInHours(now, createdAtDate);
      if (time > 24) {
        const currentResidenceContentId = cloneDeep(secureLocalStorage.getItem('bookingResidence'))?.cmsContentId;

        router.push(
          currentResidenceContentId
            ? {
              pathname: '/booking/formula',
              query: { residence: currentResidenceContentId },
            }
            : '/',
        ).then(() => {
          handleClearBookingStorage();
        });
      }
    }
  };

  const isInsideBookingTunnel = useMemo(() => (router.pathname.includes('/booking/')), [router]);

  useEffect(() => {
    if (isClientSide) {
      handleBookingFormPopulation();

      if (isInsideBookingTunnel) {
        handleBookingVersionCheck();
        handleBookingCreationDate();
      }
    }
  }, []);

  return (
    <AppStateContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>
        {children}
      </AppDispatchContext.Provider>
    </AppStateContext.Provider>
  );
}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useAppContext = () => [useContext(AppStateContext), useContext(AppDispatchContext)];

export {
  AppProvider,
  AppStateContext,
  AppDispatchContext,
  useAppContext,
};
