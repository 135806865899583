export const CURATOR_API_URL = 'https://api.curator.io/v1';

// COOKIES
export const COOKIE_VERSIONS = {
  fr: 'student-factory FR',
  en: 'student-factory EN',
};

// BOOKINGS
export const LINK_TO_USER_BOOKING_AREA = 'https://student-factory.my.site.com/clientportal/login';

// LOCALE
export const LOCALE_FR = 'fr';
export const LOCALE_EN = 'en';
export const DEFAULT_LOCALE = LOCALE_FR;

// PHONE NUMBER
export const PHONE_NUMBER = {
  [LOCALE_EN]: '+33 1 71 25 08 20',
  [LOCALE_FR]: '01 71 25 08 20',
};

// CONTACT LINKS
export const CONTACT_FORMS_LINKS = {
  cgu: { [LOCALE_FR]: '/conditions-generales-de-vente', [LOCALE_EN]: '/terms-of-sales' },
  poc: { [LOCALE_FR]: '/politique-de-confidentialite', [LOCALE_EN]: '/private-policy' },
  collect: {
    [LOCALE_FR]: '/legal',
    [LOCALE_EN]: '/en/legal',
  },
  invest: {
    [LOCALE_FR]: 'https://www.vinci-immobilier.com/charte-de-confidentialite',
    [LOCALE_EN]: 'https://www.vinci-immobilier.com/charte-de-confidentialite',
  },
  partner: {
    [LOCALE_FR]: 'https://www.vinci-immobilier.com/partenaires-vinci',
    [LOCALE_EN]: 'https://www.vinci-immobilier.com/partenaires-vinci',
  },
};

export const LINK_USER_SPACE = 'https://student-factory.my.site.com/clientportal/login';

export const FAQ_LINK = {
  [LOCALE_FR]: 'https://student-factory.my.site.com/aide',
  [LOCALE_EN]: 'https://student-factory.my.site.com/aide/s/?language=en_US',
};

// MAPS
export const DEFAULT_MAP_FRANCE_LOCATIONS = {
  lat: 46.864716,
  lng: 2.349014,
};

export const MAP_LIBRARIES = ['places'];

export const MAP_TRAVEL_MODES = {
  WALKING: 'WALKING',
  TRANSIT: 'TRANSIT',
  DRIVING: 'DRIVING',
  BICYCLING: 'BICYCLING',
};

export const MAP_TRAVEL_MODES_ICON = {
  WALKING: 'directionsWalk',
  TRANSIT: 'directionsSubway',
  DRIVING: 'directionsCar',
  BICYCLING: 'directionsBike',
};

// RESIDENCES STAY TYPES
export const MEDIUM_LONG_STAY = 'Moyen/Long Séjour';
export const SHORT_STAY = 'Court séjour';
export const CO_LIVING = 'Co-living';
export const CO_WORKING = 'Co-working';
export const RESTORATION = 'Restauration';

// SLUGS
export const RESIDENCE_SLUG = 'residence';
export const CONTACT_SLUG = 'contact';
export const BLOG_SLUG = 'conseils-et-actus';
export const CONCEPT_SLUG = 'concept';

export const SLUG_EN_STUDENT_RESIDENCES = 'student-residences';
export const SLUG_FR_STUDENT_RESIDENCES = 'residences-etudiantes';
export const SLUG_EN_APART_HOTEL = 'apart-hotel';
export const SLUG_FR_APART_HOTEL = 'appart-hotel';

export const SLUG_EN_INVEST = 'invest';
export const SLUG_FR_INVEST = 'investir-en-residence-etudiante';

export const SLUG_FR_SEARCH = 'nos-residences-etudiantes';
export const SLUG_EN_SEARCH = 'our-student-residences';

export const VALID_PREFIXS_SLUGS = [
  SLUG_EN_STUDENT_RESIDENCES,
  SLUG_FR_STUDENT_RESIDENCES,
  SLUG_EN_APART_HOTEL,
  SLUG_FR_APART_HOTEL,
];

export const URL_SLUGS_INVEST = {
  [LOCALE_FR]: SLUG_FR_INVEST,
  [LOCALE_EN]: SLUG_EN_INVEST,
};

export const URL_SLUGS_RESIDENCES = {
  [LOCALE_FR]: SLUG_FR_STUDENT_RESIDENCES,
  [LOCALE_EN]: SLUG_EN_STUDENT_RESIDENCES,
};

export const URL_SLUGS_APART_HOTEL = {
  [LOCALE_FR]: SLUG_FR_APART_HOTEL,
  [LOCALE_EN]: SLUG_EN_APART_HOTEL,
};

export const URL_SLUGS_RESIDENCES_STAY = {
  [LOCALE_FR]: {
    [SLUG_FR_STUDENT_RESIDENCES]: [MEDIUM_LONG_STAY],
    [SLUG_FR_APART_HOTEL]: [SHORT_STAY],
  },
  [LOCALE_EN]: {
    [SLUG_EN_STUDENT_RESIDENCES]: [MEDIUM_LONG_STAY],
    [SLUG_EN_APART_HOTEL]: [SHORT_STAY],
  },
};

export const URL_SLUGS_ACTIVITIES = {
  [MEDIUM_LONG_STAY]: SLUG_EN_STUDENT_RESIDENCES,
  [SHORT_STAY]: SLUG_EN_APART_HOTEL,
};

export const URL_SEARCH_PAGES = {
  [LOCALE_FR]: SLUG_FR_SEARCH,
  [LOCALE_EN]: SLUG_EN_SEARCH,
};

export const BANNED_PAGE_SLUGS = [
  SLUG_EN_STUDENT_RESIDENCES,
  SLUG_FR_STUDENT_RESIDENCES,
  SLUG_EN_APART_HOTEL,
  SLUG_FR_APART_HOTEL,
  BLOG_SLUG,
  URL_SLUGS_INVEST,
];
