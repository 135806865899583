import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

// Utils
import { cn } from 'utils';

import styles from './PageLoader.module.css';

function PageLoader() {
  const router = useRouter();

  const [isPageChanging, setIsPageChaging] = useState(false);

  const handleStart = () => {
    setIsPageChaging(true);
  };
  const handleComplete = () => {
    setTimeout(() => {
      setIsPageChaging(false);
    }, 600);
  };

  useEffect(() => {
    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
    };
  }, []);

  return (
    <div className={cn([styles.loader, 'd-flex center', isPageChanging ? styles.changing : ''])}>
      <div className={styles.layer1} />
      <div className={styles.layer2} />
      <div className={styles.layer3} />
      <div className={styles.loading_icon}>
        <div />
        <div />
      </div>
    </div>
  );
}

PageLoader.propTypes = {};

export default PageLoader;
