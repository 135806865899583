export default function appReducer({
  state, action, initStore,
}) {
  switch (action.type) {
    case 'SET_BOOKING_FORM': {
      return { ...state, bookingForm: action.payload };
    }

    case 'SET_BOOKING_RESIDENCE': {
      return { ...state, bookingResidence: action.payload };
    }

    case 'RESET_BOOKING': {
      return { ...state, bookingForm: {}, bookingResidence: null };
    }

    case 'SET_BOOKING_AND_RESIDENCE': {
      return {
        ...state,
        bookingForm: action?.payload?.bookingForm || {},
        bookingResidence: action?.payload?.bookingResidence || null,
      };
    }

    case 'RESET_STATE': {
      return initStore;
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}
