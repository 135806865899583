import { format, isValid } from 'date-fns';
import { enGB as en, fr } from 'date-fns/locale';

const locales = { en, fr };

/**
 * Format a date
 * @param {string} date - Date to format
 * @param {string} defaultFormat - Default format to use if date is invalid
 * @param {string} locale - Locale to use
 * @returns {string} Formatted date
 */
const formatDate = (date, defaultFormat = 'dd/MM/yyyy', locale = 'fr') => {
  if (date === null || !isValid(new Date(date))) return defaultFormat;
  return format(new Date(date), defaultFormat, { locale: locales[locale] });
};

export default formatDate;
